import React, { useState } from "react";

import axios from "axios";

function CalendarForm({ closeModal, selectedDate }) {
  const [time, setTime] = useState("");
  const [guesser, setGuesser] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log({ time, guesser, selectedDate });

    axios
      .post("https://bbg-api.azurewebsites.net/api/v1/guesses", {
        datetime:
          selectedDate.date.toISOString().split("T")[0] +
          "T" +
          time +
          ":00-07:00",
        guesserName: guesser,
      })
      .then((response) => {
        console.log(response);
        closeModal && closeModal();
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col p-4 md:px-5 md:py-3 border border-teal-500 rounded-xl space-y-4">
        <h1 className="text-lg">At what time will the baby be born?</h1>

        <div className="flex flex-wrap md:flex-row items-center justify-between md:justify-between md:mx-20">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-first-name"
          >
            Time:
          </label>
          <input
            className="w-full md:w-52 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            type="time"
            value={time}
            required
            onChange={(e) => setTime(e.target.value)}
          />
        </div>

        <div className="flex flex-wrap md:flex-row items-center justify-between md:justify-between md:mx-20">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-first-name"
          >
            Guesser:
          </label>
          <input
            className="w-full md:w-52 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            type="text"
            value={guesser}
            required
            onChange={(e) => setGuesser(e.target.value)}
          />
        </div>

        <div className="flex flex-wrap border-b border-teal-500 pb-2 justify-end">
          <button
            className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}
export default CalendarForm;
