import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CalendarForm from "./calendarForm";

function CalendarModal({ modalIsOpen, closeModal, selectedDate, guesses }) {
  return (
    <Modal
      className="mt-14 mx-6 sm:m-24 rounded-xl overflow-y-auto overflow-x-hidden outline-0"
      overlayClassName="fixed inset-0 w-full h-full bg-gray-700 bg-opacity-50"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Selected Date"
    >
      <div className="flex flex-col space-y-5 border rounded-xl p-4 bg-gray-200">
        <div className="flex justify-between items-center">
          <h1 className="text-xl">
            {selectedDate ? formatDate(selectedDate) : ""}
          </h1>
          <button
            onClick={closeModal}
            className="text-3xl text-primary border-primary"
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <p>
            {guesses.length === 0
              ? "No guesses for this day yet..."
              : (guesses.length === 1
                  ? "1 guess"
                  : `${guesses.length} guesses`) + " so far:"}
          </p>
          {guesses && (
            <ul className="max-h-28 overflow-auto">
              {guesses.map((guess, index) => (
                <li className="pl-3 italic" key={index}>
                  {guess.guesser} {guess.time}
                </li>
              ))}
            </ul>
          )}
        </div>
        <CalendarForm selectedDate={selectedDate} closeModal={closeModal} />
      </div>
    </Modal>
  );
}

function formatDate(date) {
  return `${date.month} ${date.day}`;
}

export default CalendarModal;
