import React from "react";

const babyLogo = "https://noonbar.blob.core.windows.net/bbg-site/bbg2.png";

const BabyLogo = () => {
  return (
    <div className="lg:w-1/3 w-2/3">
      <img
        src={babyLogo}
        className="object-contain animate-rock-baby"
        alt="baby logo"
      />
    </div>
  );
};

export default BabyLogo;
