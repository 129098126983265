import React from "react";

function RegistrySmall({ registryData }) {
  const { url, logoUrl, altText } = registryData;

  return (
    <div className=" rounded-lg hover:shadow-xl hover:-translate-y-1 dark:border-gray-700">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="object-contain size-12 " src={logoUrl} alt={altText} />
      </a>
    </div>
  );
}

export default RegistrySmall;
