import React, { useEffect, useState } from "react";
import axios from "axios";

import CalendarDay from "../components/calendarDay";
import CalendarModal from "../components/calendarModal";

const DaysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
const ProjectedDueDate = new Date(2024, 4, 8); // May 8, 2024
const NumberOfDaysBefore = 17;
const NumberOfDaysAfter = 8;
const FromDate = AddDays(ProjectedDueDate, -NumberOfDaysBefore);
const ToDate = AddDays(ProjectedDueDate, NumberOfDaysAfter);
const datesToShow = [];

function AddDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function areSameDayOfYear(date1, date2) {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
}

let lastSaturday =
  ToDate.getDay() === 6 ? ToDate : AddDays(ToDate, 6 - ToDate.getDay());
let currentDate =
  FromDate.getDay() === 0 ? FromDate : AddDays(FromDate, -FromDate.getDay());

while (currentDate <= lastSaturday) {
  datesToShow.push({
    date: currentDate,
    day: currentDate.getDate(),
    month: currentDate.toLocaleString("default", { month: "short" }),
    isDisabled: currentDate < FromDate || currentDate > ToDate,
    isDueDate: currentDate.getTime() === ProjectedDueDate.getTime(),
  });
  currentDate = AddDays(currentDate, 1);
}

function CalendarPage() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [guesses, setGuesses] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://bbg-api.azurewebsites.net/api/v1/guesses"
        );
        setGuesses(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (!modalIsOpen) {
      fetchData();
    }
  }, [modalIsOpen]);

  const handleDayClick = (date) => {
    if (date.isDisabled) {
      return;
    }

    setSelectedDate(date);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="grid self-center space-y-0.5 sm:space-y-0 mt-8 lg:mx-20 md:mx-10 mx-4 p-4 sm:grid-cols-7 border rounded-xl border-black gap-0.5">
      <div className="flex col-span-7">
        {DaysOfWeek.map((date, index) => (
          <div
            key={index}
            className="w-full min-h-8 max-w-md py-2 px-1 text-center text-white border bg-gray-600 bg-opacity-60 border-gray-400"
          >
            <h1 className="text-md font-bold leading-none">{date}</h1>
          </div>
        ))}
      </div>
      {datesToShow.map((date, index) => (
        <div onClick={() => handleDayClick(date)} key={index}>
          <CalendarDay
            key={index}
            day={date.day}
            month={date.month}
            isDisabled={date.isDisabled}
            isDueDate={date.isDueDate}
            guessList={guesses.filter((g) =>
              areSameDayOfYear(new Date(g.date), date.date)
            )}
          />
        </div>
      ))}

      <CalendarModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        selectedDate={selectedDate}
        guesses={guesses.filter(
          (g) =>
            selectedDate == null ||
            areSameDayOfYear(new Date(g.date), selectedDate.date)
        )}
      />
    </div>
  );
}

export default CalendarPage;
