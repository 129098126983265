import React from "react";
import { useLocation } from "react-router-dom";

import RegistrySmall from "./registrySmall";

import registriesToShow from "../data/registries.json";

const CopyrightText = "© 2024 Copyright: Sylvia Barcena & Jose Nunez";

const Footer = () => {
  const location = useLocation();
  const showRegistries = location.pathname === "/calendar";

  return (
    <footer className="shadow mx-4 rounded-xl mx-auto my-10 bg-blend-multiply bg-gray-700 bg-opacity-30">
      <div className="flex flex-col items-center sm:flex-row space-y-3 sm:space-x-12 p-4">
        <span className="text-sm text-white">{CopyrightText}</span>
        {showRegistries && (
          <div className="flex flex-row space-x-6 justify-around">
            {registriesToShow.map((registry, index) => (
              <RegistrySmall key={index} registryData={registry} />
            ))}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
