import React from "react";

const disabledDayStyle = "opacity-50 hover:cursor-not-allowed";
const enabledDayStyle =
  "hover:cursor-pointer hover:bg-gray-500 hover:text-white";

const dueDateClass = ""; // "bg-gray-800";

const CalendarDay = ({
  day,
  month,
  isDisabled,
  isDueDate,
  guessList,
  onClick,
}) => {
  return (
    <div
      className={`min-h-12 p-1 border border-gray-400 bg-gray-600 bg-opacity-60 sm:min-h-28 sm:p-2 ${
        isDisabled ? disabledDayStyle : enabledDayStyle
      } ${isDueDate ? dueDateClass : ""}`}
    >
      <button
        onClick={onClick}
        className={
          "w-full flex flex-col items-center space-y-4 outline-0" +
          (isDisabled ? " cursor-not-allowed" : "")
        }
      >
        <div className="w-full text-xs font-normal text-white flex flex-col sm:items-end space-y-1 sm:pb-1.5 sm:text-sm sm:flex-row sm:justify-between sm:border-b">
          <span>{month}</span>
          <span className="bg-blue-100 text-blue-800 text-lg font-bold leading-none p-0.5 sm:px-1.5 rounded dark:bg-blue-200 dark:text-blue-800">
            {day}
          </span>
        </div>
        {isDisabled ? (
          <p className="hidden sm:block text-sm text-gray-100">Unavailable</p>
        ) : (
          <div className="hidden sm:block flex flex-col space-y-3">
            {guessList.length > 0 && (
              <p className="text-sm font-normal text-gray-100 underline">
                {guessList.length}{" "}
                {guessList.length === 1 ? "guess" : "guesses"}
              </p>
            )}
            {guessList.length === 0 && (
              <p className="text-sm font-normal text-gray-100">Available</p>
            )}
          </div>
        )}
      </button>
    </div>
  );
};

export default CalendarDay;
