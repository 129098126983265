import React from "react";

/* Welcome to Sylvia & Jose's Baby Shower */
const WelcomeText = "Sylvia & Jose's Baby B-Day Guesser";

const Header = () => {
  return (
    <header>
      <div className="mt-4 px-4 mx-auto max-w-screen-xl text-center lg:mt-8">
        <p className="text-3xl bg-blend-multiply bg-gray-700 bg-opacity-30 rounded-2xl font-bold tracking-wide leading-none md:text-4xl lg:text-5xl dark:text-gray-100 shadow-2xl p-4 backdrop-opacity-20">
          {/* <button class="bg-cyan-500 bg-emerald-100 hover:bg-cyan-600 ...">Home</button> */}
          {WelcomeText}
        </p>
      </div>
    </header>
  );
};

export default Header;
