import React from "react";

import Registry from "../components/registry";
import BabyLogo from "../components/babyLogo";

import registriesToShow from "../data/registries.json";

const GoGuessText = "Make a Guess!";

function HomePage() {
  return (
    <div className="flex flex-col items-center bg-primary space-y-10">
      <BabyLogo />

      <button
        type="button"
        className="motion-safe:animate-bounce delay-2000 text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-xl px-5 py-2.5 text-center m-2"
      >
        <a href="/calendar">{GoGuessText}</a>
      </button>

      <div className="flex md:flex-row space-x-6">
        {registriesToShow.map((registry, index) => (
          <Registry key={index} registryData={registry} />
        ))}
      </div>
    </div>
  );
}

export default HomePage;
