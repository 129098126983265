import React from "react";

function Registry({ registryData }) {
  const { url, logoUrl, altText } = registryData;

  return (
    <div className="border border-gray-200 rounded-lg hover:shadow-xl hover:-translate-y-1 dark:border-gray-700">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img
          className="object-contain size-28 w-full max-w-40 p-3"
          src={logoUrl}
          alt={altText}
        />
      </a>
    </div>
  );
}

export default Registry;
